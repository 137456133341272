import { useWallet } from '@solana/wallet-adapter-react';
import { useState, useEffect } from 'react';
import Solana from '../api/solana';
import { PublicKey } from '@solana/web3.js';

const client = new Solana("https://cindie-7oi4z0-fast-mainnet.helius-rpc.com");

export const useWalletAddress = () => {
    const { connected, publicKey } = useWallet();
    const [walletAddress, setWalletAddress] = useState(null);

    useEffect(() => {
        if (connected && publicKey) {
            setWalletAddress(publicKey.toString());
        } else {
            setWalletAddress(null);
        }
    }, [connected, publicKey]);

    return walletAddress;
};

export const useWalletBalance = () => {
    const { publicKey } = useWallet();
    const [balance, setBalance] = useState(null); // Start with null to indicate loading state

    useEffect(() => {
        const fetchBalance = async () => {
            if (publicKey) { // Ensure publicKey is set
                try {
                    const bal = await client.getBalance(new PublicKey(publicKey));
                    setBalance(bal / 1e9);

                } catch (e) {
                    setBalance(0);
                }
            } else {
                setBalance(0); // Reset if no publicKey
            }
        };

        fetchBalance();
    }, [publicKey]); // Trigger when publicKey updates
    return balance;
};
