import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Linking } from 'react-native-web';
import StakeUnstakeToggle from './StakeUnstakeToggle';
import StakeCard from './StakeCard';
import StakeAccounts from './StakeAccounts';
import { useWallet } from '@solana/wallet-adapter-react';
import Solana from '../api/solana';

export default function StakingCard() {
    const [result, setResult] = useState("");
    const [txHash, setTxHash] = useState(null);
    const [isStaking, setIsStaking] = useState(true);
    const [minimumRent, setMinimumRent] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [_mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const { publicKey } = useWallet();
    useEffect(() => {
        const solana = new Solana("https://cindie-7oi4z0-fast-mainnet.helius-rpc.com");

        const fetchMinRent = async () => {
            if (publicKey) {
                const minRent = await solana.getMinimumBalanceForRentExemption();
                setMinimumRent(minRent);
            }
        }

        fetchMinRent();
    }, [publicKey, minimumRent]);

    const handleMouseMove = (event) => {
        setMousePosition({ x: event.nativeEvent.locationX, y: event.nativeEvent.locationY })
    };

    const handleTxHashPress = () => {
        Linking.openURL(`https://solscan.io/tx/${txHash}`);
    };

    const toggleStakeUnstake = (mode) => {
        setIsStaking(mode);
    };

    return (
        <ScrollView style={styles.container} contentContainerStyle={styles.scrollViewContent}>
            <View style={styles.container} onTouchMove={handleMouseMove}>
                <View style={[styles.scrollView, styles.scrollViewContent]}>
                    <Text style={styles.whiteTitle}>Stake SOL with</Text>
                    <Text style={styles.title}>Orangefin Ventures</Text>
                    <StakeUnstakeToggle
                        toggleStakeUnstake={toggleStakeUnstake}
                        isStaking={isStaking}
                    />
                    {isStaking === true ? (
                        <StakeCard
                            txHash={txHash}
                            setTxHash={setTxHash}
                            result={result}
                            setResult={setResult}
                        />
                    ) : (
                        <StakeAccounts
                            minimumRent={minimumRent}
                        />
                    )}

                </View>
            </View>
            {result !== "" ? (
                <TouchableOpacity disabled={txHash === null} style={styles.resultContainer} onPress={handleTxHashPress}>
                    <View style={styles.resultWrapper}>
                        <Text style={styles.apyLabel}>{result}</Text>
                        {txHash !== null ? (
                            <Text style={styles.apyLabel}>Click here to view transaction.</Text>
                        ) : null}
                    </View>
                </TouchableOpacity>
            ) : null}
        </ScrollView >
    )
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(255,123,34,0.2)',
        zIndex: 2,
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerLogo: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{ rotate: '-45deg' }],
    },
    connectButton: {
        borderWidth: 1,
        borderColor: '#FF8400',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    connectButtonText: {
        color: '#FF8400',
        fontWeight: 'bold',
    },
    scrollView: {
        flex: 1,
    },
    scrollViewContent: {
        flexGrow: 1,
        padding: 16,
        alignItems: 'center',
    },
    main: {
        padding: 16,
        alignItems: 'center',
    },
    whiteTitle: {
        fontSize: 36,
        fontFamily: 'PP Neue Machina Plain',
        color: '#FFFFFF',
        textAlign: 'center',
        marginBottom: 24,
    },
    title: {
        fontSize: 36,
        fontFamily: 'PP Neue Machina Plain',
        color: '#FF8400',
        textAlign: 'center',
        marginBottom: 24,
    },
    stakingCard: {
        backgroundColor: 'rgba(255,123,34,0.1)',
        borderRadius: 16,
        padding: 24,
        width: '100%',
        maxWidth: 400,
    },
    resultContainer: {
        backgroundColor: 'rgba(255,123,34,0.1)',
        borderRadius: 16,
        padding: 16,
        width: '100%',
        maxWidth: 345,
        alignItems: 'center',
    },
    resultWrapper: {
        padding: 16,
        alignItems: 'center',
    },
    balanceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    balanceLabel: {
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    balanceValue: {
        color: 'white',
        fontSize: 20,
        fontFamily: 'PP Neue Machina Plain',
    },
    inputContainer: {
        position: 'relative',
        marginBottom: 16,
    },
    input: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderWidth: 1,
        borderColor: 'rgba(255,123,34,0.5)',
        borderRadius: 8,
        color: 'white',
        fontSize: 18,
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    maxButton: {
        position: 'absolute',
        right: 50,
        top: 12,
        backgroundColor: 'rgba(255,123,34,0.3)',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 4,
    },
    maxButtonText: {
        color: '#FF8400',
        fontWeight: 'bold',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 14,
    },
    inputSuffix: {
        position: 'absolute',
        right: 16,
        top: 17,
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    apyLabel: {
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyValue: {
        color: 'white',
        fontSize: 20,
        fontFamily: 'PP Neue Machina Plain',
    },
    stakeButton: {
        backgroundColor: '#FF8400',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 8,
    },
    disabledButton: {
        opacity: 0.5,
    },
    stakeButtonText: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'PP Neue Machina Plain',
        marginRight: 8,
    },
    stakeButtonIcon: {
        marginLeft: 8,
    },
    sectionTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#FF8400',
        textAlign: 'center',
        marginTop: 32,
        marginBottom: 16,
    },
    featuresContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    featureCard: {
        backgroundColor: 'rgba(255,123,34,0.05)',
        borderRadius: 12,
        padding: 16,
        margin: 8,
        width: '30%',
        minWidth: 200,
        alignItems: 'center',
    },
    featureIcon: {
        marginBottom: 8,
    },
    featureTitle: {
        color: '#FF8400',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
        textAlign: 'center',
    },
    featureDescription: {
        color: 'rgba(255,255,255,0.7)',
        textAlign: 'center',
    },
    footer: {
        borderTopWidth: 1,
        borderTopColor: 'rgba(255,123,34,0.2)',
        padding: 16,
    },
    footerNav: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 8,
    },
    footerLink: {
        color: 'rgba(255,255,255,0.7)',
        marginHorizontal: 8,
    },
    footerText: {
        color: 'rgba(255,255,255,0.5)',
        textAlign: 'center',
    },
});